<template>
<v-container fluid class="mx-0 px-0 pb-0">
    <v-container class="">
        <v-row>
          <v-col cols="" class="mt-5">
            <div class="mt-10"> 
            <span class="title-light" :class="{'title-dark': theme }">
              Join haq.ai for a daily dose of Quantum Computing problems!
            </span>
            </div>
            <div class="mt-7 text font-weight-regular" :class="{'text-normal-dark': theme }">
              With problems in all categories for all levels
            </div>
            <v-btn class="mt-10 pa-5" color="primary" :class="{'black--text': theme}" to="/auth/register"> Sign up here</v-btn>
          </v-col>
          <v-col class="mt-5">
            <v-img max-width="449" max-height="545" :src="require('@/assets/images/home1.svg')" />
          </v-col>

        </v-row>
    </v-container>

  <v-card flat :color="grey1" class="px-0 mx-0" min-height="100">
    <v-container>
      <v-card-title class="text-h6">
        <v-spacer></v-spacer>
        <div>
          <span class="font-weight-bold"> Who is HAQ for?</span>
          <div class="mx-10 divider-light mt-3" :class="{'divider-dark': $vuetify.theme.dark}"></div>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-3">

          <v-col>
            <v-card width="247px" flat color="transparent">
              <v-card-text>
                <div>
                  <v-chip :color="icon1" class="px-5">
                    <v-icon color="white" medium>mdi-book-open-outline</v-icon>
                  </v-chip>
                </div>
                <div class="mt-2">
                  <span class="text-h6 font-weight-medium">Enthusiasts</span>
                </div>

                <div>
                  <span :class="{'text-normal-dark': theme }">
                    haq acts as a repository for fun problems and challenges that are being designed and updated by the day, meaning that you can learn a lot, regardless of whether you have a direct motive or not
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card width="247px" flat color="transparent">
              <v-card-text>
                <div>
                  <v-chip :color="icon2" class="px-5">
                    <v-icon color="white" medium>mdi-remote-desktop</v-icon>
                  </v-chip>
                </div>
                <div class="mt-2">
                  <span class="text-h6 font-weight-medium">Students</span>
                </div>
                <div>
                  <span :class="{'text-normal-dark': theme }" >
                    With custom challenges coming out, you can test your knowledge of fields ranging from QEC to QML by solving research-based problems
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card width="247px" flat color="transparent">
              <v-card-text>
                <div>
                  <v-chip :color="icon3" class="px-5">
                    <v-icon color="white" medium>mdi-sofa-single</v-icon>
                  </v-chip>
                </div>
                <div class="mt-2">
                  <span class="text-h6 font-weight-medium">Educators</span>
                </div>
                <div>
                  <span :class="{'text-normal-dark': theme }">
                    haq  acts as a bridge between theoretical and pracical work, and could be a great supplementary tool for teaching QC/QI
                  </span>
                </div>
              </v-card-text>
            </v-card>  
          </v-col>

          <v-col>
            <v-card width="247px" flat color="transparent">
              <v-card-text>
                <div>
                  <v-chip :color="icon4" class="px-5">
                    <v-icon color="white" medium>mdi-cellphone-dock</v-icon>
                  </v-chip>
                </div>
                <div class="mt-2">
                  <span class="text-h6 font-weight-medium">Hackathon</span>
                </div>
                <div>
                  <span :class="{'text-normal-dark': theme }">
                    haq, by design, is a competitive quantum computing platform, so whether you want to host or attend a hackathon, we’re here for you!
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn to="/auth/register" class="mt-10 pa-5" color="primary" :class="{'black--text': theme}"> Sign up Now</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-container>
  </v-card>

  <ChallengeComponent />

   <!-- learn qc  -->
  <v-card flat class="pb-5">
    <v-container>
      <v-card-title class="text-h6 mt-5">
        <v-spacer></v-spacer>
        <div>
          <span class="font-weight-bold"> Learn Quantum Computing </span>
          <div class="mx-10 mt-3 divider-light" :class="{'divider-dark': $vuetify.theme.dark}"></div>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="mt-5 text-center mx-15 learn-qc">
        While haq is a beginner friendly website, and has challenges aimed at the introductory level, it is best that you have at least basic knowledge of quantum computing. However, don’t worry if you don’t, we have dozens of  problems which can supplement your early stage journey. On top of that, here are three recommended resources for learning quantum computing from the ground up: The Qiskit Textbook, The Eigensolver Quantum School and Nielsen and Chuang 
      </v-card-text>

      <v-row class=" mt-5">
        <v-col>
          <v-card min-height="213px" class="rounded-lg" :color="grey1">
            
            <v-card-title class="mt-10">
              <v-spacer></v-spacer>
              <span> Qiskit Textbook </span>
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text class="text-center">
              <span>The qiskit textbook is a comprehensive guide to both Quantum Computing and the Qiskit platfrom. It assumes no prior knowledge and goes through topics ranging from Quantum Error Correction to Pulses</span>
            </v-card-text>

            <v-card-actions class="">
              <v-spacer></v-spacer>
              <v-btn target="_blank" href="https://qiskit.org/textbook/preface.html" text :color="primaryBlue"> Qiskit Textbook <v-icon>mdi-arrow-right</v-icon></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
       <v-col>
          <v-card min-height="213px" class="rounded-lg" :color="grey1">
            
            <v-card-title class="mt-10">
              <v-spacer></v-spacer>
              <span> TEQS </span>
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text class="text-center">
              <span> In the summer of 2021, The Eigensolvers conducted a 1 week QC crash course + hackathon with some collaboration with IBMQ. Here you can find all lectures, excercises, bonus content and more!</span>
            </v-card-text>

            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn target="_blank" href="https://github.com/The-Eigensolvers/TEQS" text :color="primaryBlue">TEQS <v-icon>mdi-arrow-right</v-icon></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          
          </v-card>
        </v-col>
        <v-col>
          <v-card min-height="213px" :color="grey1" class="rounded-lg">
            
            <v-card-title class="mt-10">
              <v-spacer></v-spacer>
              <span>Nielsen and Chuang</span>
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text class="text-center">
              <span>
                Often called the “Bible of Quantum Computing”, Neilsen and Chuang is arguably the “go-to” textbook for any introductary QC/QI course. Regardless of whether you plan on reading it all, it is certainly a reference you’d want to have!
              </span>
            </v-card-text>
            <v-card-actions >
              <v-spacer></v-spacer>
              <v-btn target="_blank" href="https://www.amazon.com/Quantum-Computation-Information-10th-Anniversary/dp/1107002176/ref=sr_1_1?dchild=1&keywords=nielsen+and+chuang&qid=1632769778&sr=8-1"  text :color="primaryBlue">Nielsen and Chuang <v-icon>mdi-arrow-right</v-icon></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          
          </v-card>
        </v-col>
      </v-row>


    </v-container>
  </v-card>
  <!-- learn qc  -->

  <!-- contribute  -->
  <Contribute />
  <!-- contribute  -->

  <!-- fun  -->
  <v-card flat class="pb-10">
    <v-container>
      <v-row>
        <v-col>
          <div class="ml-5 mt-10">
            <v-img v-if="theme" max-height="300px" width="80%"  :src="require('@/assets/images/fun-dark.svg')"></v-img>
            <v-img v-else max-height="423px" width="541px"  src="https://haqai.s3.us-east-2.amazonaws.com/assets/images/tumblr_a02bc31f432696006b1a7bb99463f924_c7acbd73_1280-1.gif"></v-img>

          </div>
        </v-col>
        <v-col>
          <v-card flat color="transparent" class="mt-10">
            <v-card-title>
              <span class="text-h3 font-weight-bold"> Ready to start  <br/> haqing? </span>
            </v-card-title>

            <v-card-actions class="mt-5 ml-2">
              <v-btn class="pa-5" color="primary" to="/auth/register"> Get started Now </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>        
      </v-row>
    </v-container>
  </v-card>
  <!-- fun  -->

  <!-- donate  -->
  <Donate />
  <!-- donate  -->
</v-container>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';

export default {
  name: 'homePage',
  components: {
    ChallengeComponent: () => import('./_components/challengesComponent'),
    Contribute: () => import('./_components/contibute'),
    Donate: () => import('./_components/Donate')
  },
  mixins: [colorMxn],
  data() {
    return {
      model: 0,
      challenges: [],
    }
  },
}
</script>

<style scoped>
.title-light {
  font-family: Nunito,serif;
  font-weight: 800;
  font-size: 42px;
  line-height: 57px;
  letter-spacing: -0.015em;
  color: #1748CF;
}
.title-dark {
  font-weight: 800;
  font-size: 42px;
  line-height: 57px;
  letter-spacing: -0.015em;
  color: #9BB2F3;
  font-family: Nunito,serif;
}

.text-justify {
  display: flex;
  align-items: center;
  text-align: center;
}

</style>